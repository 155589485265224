

































import { Component, Vue } from 'vue-property-decorator';
import { ICategory, ICategoryUpdate } from '@/interfaces';
import { dispatchGetCategories, dispatchUpdateCategory } from '@/store/admin/actions';
import { readAdminOneCategory } from '@/store/admin/getters';

@Component
export default class EditCategory extends Vue {
  public valid = false;
  public name = '';
  public parentId = null;

  public async mounted() {
    await dispatchGetCategories(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.category) {
      this.name = this.category.display_name;
      this.parentId = this.$store.getters.adminCategories.filter(
          (cat) => cat.id === this.category?.parent_id,
      )[0]?.id || null;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedCategory: ICategoryUpdate = {};
      if (this.name) {
        updatedCategory.display_name = this.name;
      }
      if (this.parentId) {
        updatedCategory.parent_id = this.parentId || undefined;
      }
      await dispatchUpdateCategory(this.$store, { id: this.category!.id, category: updatedCategory});
      this.$router.push('/main/admin/categories');
    }
  }

  get category() {
    return readAdminOneCategory(this.$store)(+this.$router.currentRoute.params.id);
  }
}
