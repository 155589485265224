







































import { Component, Vue } from 'vue-property-decorator';
import { IVideoUpdate } from '@/interfaces';
import { dispatchGetVideos, dispatchUpdateVideo } from '@/store/admin/actions';
import { readAdminOneVideo } from '@/store/admin/getters';

@Component
export default class EditVideo extends Vue {
  public valid = false;
  public name: string = '';
  public duration: number = 0;
  public teacher: string = '';
  public date: string = '';
  public equipment: string = '';
  public level: number = 0;
  public minAge: number = 0;
  public maxAge: number = 0;
  public reference: string = '';
  public visibility: string = '';
  public previousVideoID: number | null = null;
  public categoryID: number | null = null;

  public async mounted() {
    await dispatchGetVideos(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    this.valid = false;
    if (this.video) {
      this.name = this.video.name;
      this.reference = this.video.reference;
      this.duration = this.video.duration;
      this.teacher = this.video.teacher;
      this.date = this.video.production_date;
      this.equipment = this.video.equipment;
      this.level = this.video.level;
      this.minAge = this.video.min_age;
      this.maxAge = this.video.max_age;
      this.visibility = this.video.visibility;
      this.previousVideoID = this.video.previous_video_id;
      this.categoryID = this.video.category_id;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedVideo: IVideoUpdate = {};
      updatedVideo.name = this.name;
      updatedVideo.reference = this.reference;
      updatedVideo.duration = this.duration;
      updatedVideo.teacher = this.teacher;

      updatedVideo.production_date = this.date;
      updatedVideo.equipment = this.equipment;
      updatedVideo.level = this.level;
      updatedVideo.min_age = this.minAge;
      updatedVideo.max_age = this.maxAge;
      updatedVideo.visibility = this.visibility;

      if (this.previousVideoID) {
        updatedVideo.previous_video_id = this.previousVideoID;
      }
      updatedVideo.previous_video_id = this.previousVideoID;
      if (this.categoryID) {
        updatedVideo.category_id = this.categoryID;
      }

      await dispatchUpdateVideo(this.$store, { id: this.video!.id, video: updatedVideo});
      this.$router.push('/main/admin/videos');
    }
  }

  get video() {
    return readAdminOneVideo(this.$store)(+this.$router.currentRoute.params.id);
  }
}
