

































import { Component, Vue } from 'vue-property-decorator';
import { ISubUpdate } from '@/interfaces';
import { dispatchGetSubs, dispatchUpdateSub } from '@/store/admin/actions';
import { readAdminOneSub } from '@/store/admin/getters';

@Component
export default class EditSub extends Vue {
  public valid = false;
  public name: string = '';
  public price: number = 0.0;
  public categories: object[] = [];
  public hidden: boolean = false;

  public async mounted() {
    await dispatchGetSubs(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    this.valid = false;
    if (this.sub) {
      this.name = this.sub.name;
      this.price = this.sub.price;
      this.hidden = this.sub.hidden;
      this.categories = this.sub.categories;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedSub: ISubUpdate = {};
      updatedSub.name = this.name;
      updatedSub.price = this.price;
      updatedSub.hidden = this.hidden;

      await dispatchUpdateSub(this.$store, { id: this.sub!.id, sub: updatedSub});
      this.$router.push('/main/admin/subs');
    }
  }

  get sub() {
    return readAdminOneSub(this.$store)(+this.$router.currentRoute.params.id);
  }
}
